/* eslint-disable no-unused-vars */
import React from "react";
import "./Services.css";
import service4 from "../../assets/service/service4.jpg";
import service2 from "../../assets/service/service2.jpg";
import service3 from "../../assets/service/service3.jpg";
import service1 from "../../assets/service/service1.jpg";
import service5 from "../../assets/service/service5.jpg";
import service6 from "../../assets/service/service6.jpg";

const Services = () => {
  return (
    <div className="services" id="services">
      <div className="service">
        <img src={service4} height={410}></img>
        <div className="caption">
          <p>
            WE CONSTRUCT <br /> YOUR HOME
          </p>
        </div>
      </div>
      <div className="service">
        <img src={service1}></img>
        <div className="caption">
          <p>
            CO CREATE
            <br /> YOUR DESIGN
          </p>
        </div>
      </div>
      <div className="service">
        <img src={service3}></img>
        <div className="caption">
          <p>
            SELECT YOUR <br /> MATERIAL
          </p>
        </div>
      </div>
      <div className="service">
        <img src={service2}></img>
        <div className="caption">
          <p>
            COMPLETE
            <br />
            TRANSPERENCY
            <br /> IN WORK
          </p>
        </div>
      </div>
      <div className="service">
        <img src={service5} height={410}></img>
        <div className="caption">
          <p>
            EXPEREINCED <br />
            TEAM TO <br /> WORK WITH
          </p>
        </div>
      </div>
      <div className="service">
        <img src={service6} height={410}></img>
        <div className="caption">
          <p>
            TIMELY
            <br /> DELIVERY TO
            <br />
            CLIENT
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
