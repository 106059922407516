import React from "react";
import "./Gallery.css";
import gallery1 from "../../assets/about/gallery1.jpg";
import gallery2 from "../../assets/about/gallery2.jpg";
import gallery3 from "../../assets/about/gallery3.jpg";
import gallery4 from "../../assets/about/gallery8.mp4";
import gallery5 from "../../assets/about/gallery5.mp4";
import gallery6 from "../../assets/about/gallery6.mp4";

const Gallery = () => {
  return (
    <div className="gallery" id="gallery">
      <div className="work">
        <img src={gallery1} alt=""></img>
        <img src={gallery2} alt=""></img>
        <img src={gallery3} alt=""></img>
        <video
          src={gallery4}
          width="280"
          height="210"
          controls="controls"
          autoPlay="true"
          loop="true"
        ></video>
        <video
          src={gallery5}
          width="280"
          height="210"
          controls="controls"
          autoPlay={true}
          loop="true"
        ></video>
        <video
          src={gallery6}
          width="280"
          height="210"
          controls="controls"
          autoPlay={true}
          loop="true"
        ></video>
      </div>
    </div>
  );
};

export default Gallery;
