import Navbar from "./Components/Navbar/Navbar";
import Hero from "./Components/Hero/Hero";
import Services from "./Components/Services/Services";
import Title from "./Components/Title/Title";
import About from "./Components/About/About";
import Gallery from "./Components/Gallery/Gallery";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";

const App = () => {
  return (
    <div>
      <Navbar></Navbar>
      <Hero></Hero>
      <div className="container">
        <Title subTitle="OUR SERVICES" title="What We Offer" />
        <Services />
        <About></About>
        <Title subTitle="GALLERY" title="Let's Look At Our Work" />
        <Gallery />
        <Title subTitle="CONTACT US" title="Let's Get In Touch" />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default App;
