import React from "react";
import "./About.css";
import about from "../../assets/about/about.png";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="about-left">
        <img src={about} className="about-img"></img>
      </div>
      <div className="about-right">
        <h3>WHO WE ARE??</h3>
        <h2>SAMARTH GUPTA:OWNER OF SUMAN CONSTRUCTIONS PVT LTD.</h2>
        <p>
          Suman Const. is an Indian construction service company and is a
          leading builder in diverse market segments. The company has earned
          recognition for undertaking large and complex projects, fostering
          innovation, embracing emerging technologies, and making a difference
          for their clients, employees and community. With a staff of over
          10,000 employees, company offers clients the accessibility and support
          of a multi-national organization. We plan and deliver building
          projects of all types and sizes—Govt. centers,Personalised Homes and
          everything else that comes in!!
        </p>
      </div>
    </div>
  );
};

export default About;
