import React from "react";
import "./Contact.css";
import message from "../../assets/about/msg-icon.png";
import mail from "../../assets/about/mail-icon.png";
import phone from "../../assets/about/phone-icon.png";
import location from "../../assets/about/location-icon.png";

const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "836187bf-e51c-4f89-868e-9e6c88a16a4c");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Email Sent Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <div className="contact" id="contact">
      <div className="contact-col">
        <h3>
          Send us a message <img src={message}></img>
        </h3>
        <p>
          Feel free to reach out through contact form or find our contact
          information below. Your feedback, questions, and suggestions are
          important to us as we strive to provide exceptional service to our
          business.
        </p>
        <ul>
          <li>
            <img src={mail}></img>samarthgupta1592@gmail.com
          </li>

          <li>
            <img src={phone}></img>+91-987654321
          </li>

          <li>
            <img src={location}></img>128/55,D-Block,Kidwai Nagar,Kanpur
          </li>
        </ul>
      </div>
      <div className="contact-col">
        <form onSubmit={onSubmit}>
          <label>Your Name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter Your name"
            required
          ></input>
          <label>Phone Number</label>
          <input
            type="tel"
            name="phone"
            placeholder="Enter Your mobile number"
            required
          ></input>
          <label>Your Email</label>
          <input
            type="text"
            name="email"
            placeholder="Enter Your email id"
          ></input>
          <label>Write your messages here</label>
          <textarea
            name="message"
            rows="6"
            placeholder="Enter your message"
            required
          ></textarea>
          <button type="submit" className="btn">
            Submit now
          </button>
        </form>
        <span>{result}</span>
      </div>
    </div>
  );
};

export default Contact;
