/* eslint-disable no-unused-vars */
import React from "react";
import "./Hero.css";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <div className="hero container" id="hero">
      <div className="hero-text">
        <h1>SUMAN CONSTRUCTIONS</h1>
        <br />
        <h3>You DREAM It,We BUILD It</h3>
        <p>
          Our cutting-edge work culture is designed to empower our clients with
          best experiences needed to excel in the dynamic field of home culture
        </p>
        <Link
          to="about"
          smooth={true}
          offset={-260}
          duration={500}
          className="btn"
        >
          Explore more
        </Link>
      </div>
    </div>
  );
};

export default Hero;
